import {register} from '@ohoareau/react-widget';
import App from '@maarsdev/react-widget';
import * as serviceWorker from './serviceWorker';
import {createSdk} from '@maarsdev/sdk';
import "@maarsdev/react-ui/assets/css/main.css";

register({
    document,
    window,
    key: 'maarsw',
    context: {
        sdk: createSdk(
            process.env.REACT_APP_API_URL,
            process.env.REACT_APP_GQL_URL,
            window.fetch.bind(window)
        )
    },
    configure: async (id, {sdk}) => {
        let r;
        try {
            r = await sdk.getPublicWidgetConfiguration(id);
        } catch (error) {
            r = {error};
        }
        return r;
    },
    component: App,
})
    .then(() => {
        // widget loaded and rendered
    })
    .catch(e => {
        console.error(e)
    }).finally(() => {
        serviceWorker.unregister();
    })
;